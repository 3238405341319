import React from 'react'
import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { BsYoutube } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import { PiArrowRightBold } from "react-icons/pi";
import { FaArrowRightLong } from "react-icons/fa6";
import pic from 'src/assets/frame2.png'

const FooterPage = () => {
  return (
    <div className='flex flex-col w-full gap-16 bg-[#131313] py-5 pt-16 px-16'>
       <div className='flex md:flex-row flex-col w-full'>
          <div className='flex flex-col w-full gap-8 md:gap-6'>
            <a href="/" className='font-medium  text-5xl md:text-6xl text-[#0ce0ef] font-Bangers w-fit'>SnapBox</a>
            <img src={pic} alt='frame' className='md:hidden w-fit h-[200px] mt-5'/>
            <h1 className='text-lg md:text-2xl text-[#edf5ff] font-medium font-Faculty_Font md:mt-36'>Your Event, Our Magic</h1>
            <h1 className='text-3xl md:text-4xl text-[#e3e3e3] font-medium font-Faculty_Font '>Let's <span className='font-bold bg-gradient-to-r from-[#00FFB7] to-[#009DFF] text-transparent bg-clip-text font-Aclonica'>Make Your Moments</span></h1>
            <button className='flex gap-3  text-nowrap items-center w-fit py-3 px-8 rounded-3xl bg-gradient-to-r from-[#62FF07] to-[#06D1FF] font-bold font-Faculty_Font'>Expore here<FaArrowRightLong className='text-xl'/></button>
          </div>
          <div className='w-full hidden md:flex justify-end pr-10'>
             <img src={pic} alt='frame' className='w-fit h-[400px]'/>
          </div>
       </div>

       <div className='w-full flex flex-col gap-6 md:gap-0 md:flex-row  md:justify-between text-white'>
          <h1 className='font-Faculty_Font text-[10px] md:text-[13px]'>© Copyright 2024, SnapBox All rights reserved.</h1>
          <div className='flex gap-10 items-start md:items-center md:justify-center md:pr-12 pb-4 md:pb-0'>
             <a href='https://www.instagram.com/snapbox.in'><FaInstagram className='text-3xl hover:opacity-30'/></a>
             <a href=''><BsYoutube className='text-3xl hover:opacity-30'/></a>
             <a href=''><BsLinkedin className='text-2xl hover:opacity-30'/></a>
             <a href=''><BsTwitterX className='text-2xl hover:opacity-30'/></a>
          </div>
       </div>
    </div>
  )
}

export default FooterPage