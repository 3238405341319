import React, { useState } from 'react'
import Test from './Pages/Test';
import Snapshots from './Pages/Snapshots';
import SnapStores from './Pages/SnapStores';
import ReviewPage from './Pages/ReviewPage';
import FooterPage from './Pages/FooterPage';
import EventPage from './Pages/EventPage';

const Layout = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col items-center h-screen min-w-full bg-background max-h-screen">
        <nav className={`z-50 flex justify-between items-center fixed h-[70px] ${isOpen ?'rounded-t-3xl': 'rounded-full'} md:rounded-3xl px-7 py-3 w-[90%] max-w-[900px] mt-5 font-Bangers shadow-lg shadow-slate-500 bg-[#171720]`}>
            <a href="/" className="font-medium text-2xl md:text-3xl text-[#0ce0ef]">SnapBox</a>

            {/* Main Navigation Menu */}
            <div className="hidden md:flex gap-4 items-center text-white">
                <ul className="flex items-center gap-6 md:gap-10">
                  <li className="border-b-2 border-cyan-400">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">Gallery</a>
                  </li>
                  <li>
                    <a href="/">Events</a>
                  </li>
                  <li>
                    <a href="/">Contacts</a>
                  </li>
                  <li className="bg-gradient-to-r from-[#00eaff] to-[#015eff] text-slate-900 py-2 px-4 md:px-5 rounded-3xl">
                    <a href="/">Let's Talk</a>
                  </li>
                </ul>
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden flex">
                <button onClick={() => setIsOpen(!isOpen)} className="text-white focus:outline-none">
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    {isOpen ? (
                      <path d="M6 18L18 6M6 6l12 12"></path>
                    ) : (
                      <path d="M4 6h16M4 12h16m-7 6h7"></path>
                    )}
                  </svg>
                </button> 
            </div>

            {/* Mobile Menu Content */}
            {isOpen && (
              <div className="absolute top-[60px] right-0 mt-2 w-full bg-[#171720]  transition-transform duration-300 shadow-lg rounded-b-3xl md:hidden">
                <ul className="flex flex-col gap-5 p-5 text-md px-7 text-white">
                  <li className="border-b w-fit border-cyan-400 pb-1">
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/">Gallery</a>
                  </li>
                  <li>
                    <a href="/">Events</a>
                  </li>
                  <li>
                    <a href="/">Contacts</a>
                  </li>
                  <li className="bg-gradient-to-r from-[#00eaff] to-[#015eff] text-slate-900 py-2 px-4 rounded-3xl text-center mt-2">
                    <a href="/">Let's Talk</a>
                  </li>
                </ul>
              </div>
            )}
        </nav>
        
        <div className="flex flex-col w-full">
            <Test/>
            {/* <Snapshots/> */}
            {/* <SnapStores/> */}
            {/* <ReviewPage/> */}
            {/* <EventPage/> */}
            <FooterPage/>
        </div>
    </div>
  )
}

export default Layout;
